<template>
  <div class="hello">
    <div class="alipay" :class="payWay==='alipay'?'bgblue':''" @click="payWays('alipay')">
    </div>
    <div class="wxpay" :class="payWay==='wePay'?'bgblue':''" @click="payWays('wePay')">
      <img src="../assets/img/WePay.png" style="width:170px;height:60px">
    </div>
    <div class="moneypay" :class="payWay==='bank'?'bgblue':''" @click="payWays('bank')">
      <img src="../assets/img/bank.png" style="width:40px;height:40px;margin-right:10px">
      <span style="font-size:30px;line-height:60px;font-weight:500">对公转账</span>
      <span>到账后两个工作日内开通</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'payWay',
  props: {
    msg: String
  },
  data() {
    return {
      payWay: ''
    };
  },
  methods: {
    payWays(way) {
      this.payWay = way;
      this.$emit('payWay', way);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hello {
  display: flex;
  justify-content: space-around;
  .alipay,
  .wxpay,
  .moneypay {
    position: relative;
    width: 217px;
    border-radius: 10px;
    border: 1px solid #eee;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .moneypay{
    flex-direction: row;
    flex-wrap: wrap;
  }
  .alipay {
    background-image: url(../assets/img/alipay.png);
    background-size: 150px 60px;
    background-repeat: no-repeat;
    background-position: center;
  }
}
.bgblue {
  background-color: #eef5fe;
}
</style>
