<template>
  <div>
    <div class="nav">订购服务/订单支付</div>
    <div class="newProject">
      <div class="left">
        <!-- <div class="title">当前项目信息</div> -->
        <el-row>
          <el-col :span="3" class="nav_title">
            当前项目名称
          </el-col>
          <el-col :span="16" class="nav_content">
            {{this.$store.state.current_project.organization.name}}
          </el-col>
        </el-row>
        <el-row v-if="!this.$route.params.info.recipient.address">
          <el-col :span="3" class="nav_title">
            开通项目名称
          </el-col>
          <el-col :span="16" class="nav_content">
            {{this.$route.params.info.name}}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3" class="nav_title" v-if="!this.$route.params.info.recipient.address">
            单位企业全称
          </el-col>
          <el-col :span="3" class="nav_title" v-else>
            收货人名称
          </el-col>
          <el-col :span="16" class="nav_content">
            {{this.$route.params.info.full_name || this.$route.params.info.recipient.recipient_name}}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3" class="nav_title">
            联系电话号码
          </el-col>
          <el-col :span="16" class="nav_content">
            {{this.$route.params.info.phone || this.$route.params.info.recipient.phone}}
          </el-col>
        </el-row>
        <el-row v-if="this.$route.params.info.recipient.address">
          <el-col :span="3" class="nav_title" >
            收货详细地址
          </el-col>
          <el-col :span="16" class="nav_content">
            {{this.$route.params.info.recipient.address}}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3" class="nav_title">
            {{this.$route.params.info.recipient.address?'硬件名称':'产品服务'}}
          </el-col>
          <el-col :span="16" class="nav_content">
            {{this.$route.params.info.projectName}}
          </el-col>
        </el-row>
        <el-row v-if="this.$route.params.info.recipient.address">
          <el-col :span="3" class="nav_title" >
            硬件数量
          </el-col>
          <el-col :span="16" class="nav_content">
            {{this.$route.params.info.order_items[0].quantity}}
          </el-col>
        </el-row>
        <el-row v-if="this.$route.params.info.recipient.address">
          <el-col :span="3" class="nav_title" >
            硬件单价
          </el-col>
          <el-col :span="16" class="nav_content">
            {{this.$route.params.info.order_items[0].price}}
          </el-col>
        </el-row>
         <el-row v-if="this.$route.params.info.recipient.address">
          <el-col :span="3" class="nav_title" v-if="this.$route.params.info.recipient.address">
            物流费用
          </el-col>
          <el-col :span="16" class="nav_content">
            {{this.$route.params.info.order_items[this.$route.params.info.order_items.length-1].price}}
          </el-col>
        </el-row>
        <el-row>
          <el-col :offset="19" :span="4" class="nav_content" style="font-size:20px;font-weight:600;">
            总金额: <span style="font-size:26px;font-weight:600;color:red">￥{{this.$route.params.info.price}}</span>
          </el-col>
        </el-row>
        <el-row style="margin-top:30px">
          <el-col :span="3" class="nav_title">
            付款方式
          </el-col>
          <el-col :span="20" class="nav_content">
            <pay-way @payWay='choicePayWay'></pay-way>
          </el-col>
        </el-row>
        <el-row style="margin-top:30px">
          <el-col :offset="20" :span="4" class="nav_content">
            <el-button type="primary" round :disabled='!payaWay==true' size='small' @click="toPay">确认付款</el-button>
          </el-col>
        </el-row>
      </div>
    </div>
    <div v-html="alipayfrom">{{this.alipayfrom }}</div>
    <!-- 对公转账弹窗 -->
    <el-dialog title="对公转账" :visible.sync="bankPayVisible" width="40%">
      <el-row style="height:40px">
        <el-col :span="5" style="font-weight:700">
          企业全称
        </el-col>
        <el-col :span="10">
          {{bankAccount.name}}
        </el-col>
      </el-row>
      <el-row style="height:40px">
        <el-col :span="5" style="font-weight:700">
          银行账号
        </el-col>
        <el-col :span="10">
          {{bankAccount.number}}
        </el-col>
      </el-row>
      <el-row style="height:40px">
        <el-col :span="5" style="font-weight:700">
          开户银行
        </el-col>
        <el-col :span="10">
          {{bankAccount.bank}}
        </el-col>
      </el-row>
      <el-row style="height:40px">
        <el-col :span="5" style="font-weight:700">
          订单编号
        </el-col>
        <el-col :span="10">
          {{this.$route.params.info.numbers}}
        </el-col>
      </el-row>
      <el-row style="height:40px">
        <el-col :span="5" style="font-weight:700">
          交易流水
        </el-col>
        <el-col :span="10">
          <el-input v-model="serial_number" placeholder="请转账成功后填写交易流水号" size="mini"></el-input>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="bankPayVisible = false">取 消</el-button>
        <el-button type="primary" @click="queryBankpay">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 微信支付弹窗 -->
    <el-dialog :title="
        `扫描二维码，进行微信支付`
      " @opened="qsCOdeVisibleOPen" :modal='false' :visible.sync="qsCOdeVisibles" :show-close='false' width="30%" v-show="qsCOdeVisible">
      <div id="qrcode" ref="qrcode"></div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="qsCOdeVisible = false">取 消</el-button>
        <el-button type="primary" @click="querywxpay" style="margin-left:30px !important">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { order, account } from '@/utils/api';
import { isTelAvailable } from '@/utils/verification';
import qs from 'qs';
import payWay from '@/components/payWay.vue';
import price from '@/components/price.vue';
import QRCode from 'qrcodejs2';
export default {
  name:'pay',
  data() {
    return {
      priceArr: [],
      agree: false,
      order: {
        product_id: '',
        remark: '',
        phone: '',
        full_name: ''
      },
      product: '',
      payaWay: '',
      bankPayVisible: false,
      serial_number: '',
      bankAccount: {},
      qsCOdeVisible: false,
      qsCOdeVisibles: true,
      alipaywayshow: true,
      alipayfrom: '',
      todayData:{
        prescribed_count:0,
        prescribed_patrol_count:0
      }
    };
  },
  components: { payWay, price },
  computed: {
    user_info() {
      return this.$store.state.user_info;
    },
    service_level() {
      let arr = ['免费版本', '基础版本', '高级版本', '定制版本'];
      return arr[this.$store.state.current_project.organization.service_level];
    }
  },
  methods: {
    // async getPriceList(cate) {
    //   const res = await this.$http.get(order.listProduct + '?scene=' + cate);
    //   this.renew = res.data.filter(item => {
    //     item.discount = ((item.price / item.original_price) * 10).toFixed(1);
    //     if (item.discount == 10) {
    //       item.discount = null;
    //     }
    //     return item.service_level == 1;
    //   });
    //   this.upgrade = res.data.filter(item => {
    //     item.discount = ((item.price / item.original_price) * 10).toFixed(1);
    //     if (item.discount == 10) {
    //       item.discount = null;
    //     }
    //     return item.service_level == 2;
    //   });
    //   console.log(this.upgrade);
    //   console.log(this.renew);
    //   this.priceArr = this.renew;
    // },
    concact(e) {
      this.product = e;
      this.order.product_id = e.id;
    },
    choicePayWay(e) {
      this.payaWay = e;
      console.log(e);
    },

    toPay() {
      this.$message.success(this.payaWay);
      if (this.payaWay == 'bank') {
        this.bankPayVisible = true;
      } else if (this.payaWay == 'wePay') {
        this.qsCOdeVisibleOPen();
      } else if (this.payaWay == 'alipay') {
        this.alipayway();
      }
    },
    async getbankAccount() {
      const res = await this.$http.get('system/bankAccount');
      this.bankAccount = res.data;
    },
    async queryBankpay() {
      if (!this.serial_number) {
        return this.$message.error('请填写交易流水号');
      }
      const res = await this.$http.post(order.payBank, {
        id: this.$route.params.info.id,
        serial_number: this.serial_number
      });
      if (res.code === 0) {
        this.$message.success('将在转账成功后的两个工作日内开通');
        this.bankPayVisible=false
      } else {
        this.$message.error('请联系管理员');
      }
    },
    async querywxpay() {
      setTimeout(async () => {
        const res = await this.$http.get(
          order.payQuery + '?id=' + this.$route.params.info.id
        );
        if (res.data.state == 2) {
          let that = this;
          this.$message.success('支付成功');
          setTimeout(async function() {
            await that.getPersonnelInfo();
            that.router.push('orderlist');
            window.location.reload();
          }, 3000);
        } else {
          this.$message.error('系统检测还未支付哦，请尽快付款');
          this.qsCOdeVisible = false;
        }
      }, 5000);
    },
    // 二维码系列
    qrCode(url) {
      let qrcode = new QRCode('qrcode', {
        width: 150, //图像宽度
        height: 150, //图像高度
        colorDark: '#000000', //前景色
        colorLight: '#ffffff', //背景色
        typeNumber: 4,
        correctLevel: QRCode.CorrectLevel.H //容错级别 容错级别有：（1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
      });
      qrcode.makeCode(url); //生成另一个新的二维码
    },
    async qsCOdeVisibleOPen() {
      if (!this.$refs.qrcode.innerHTML) {
        const res = await this.$http.get(
          `${order.payWechat}?id=${this.$route.params.info.id}`
        );
        console.log(res);
        this.qrCode(`${res.data.code_url}`);
      }
      this.qsCOdeVisible = true;
    },
    async alipayway() {
      console.log(12345);
      const res = await this.$http.get(
        order.payAlipay + '?id=' + this.$route.params.info.id
      );
      console.log(res);
      this.alipayfrom = res.data.form;
      await this.$forceUpdate();
      document.forms[0].setAttribute('target', '_blank');
      document.getElementById('alipaysubmit').submit();
      //浏览器切换页面事件
      this.$confirm('请问是否完成支付?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.querywxpay();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '请尽快支付哦'
          });
        });
    },
    // 获取用户个人信息
    async getPersonnelInfo() {
      const res = await this.$http.get(account.info);
      this.$store.commit('setUserInfo', res.data);
      // this.$message.success('登录成功')
      let currentProject = this.$store.state.user_info.members.find(
        item.organization_id == this.$store.state.projectValue
      );
      this.$store.commit('setCurrentProject', res.currentProject);
      // window.location.onload()
    }
  },
  created() {
    this.getbankAccount();
    console.log(this.$route.params);
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
.newProject {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 1200px;
  height: 800px;
  background: #ffffff;
  box-shadow: 0px 2px 15px 0px rgba(0, 26, 59, 0.26);
  border: 1px solid #e6ecf5;
  margin: 20px auto;
  .left {
    flex: 1;
    padding-left: 100px;
    padding-top: 60px;
    padding-right: 30px;
    .title {
      font-size: $fontSizeMap-20;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: 28px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}
.nav {
  background-color: #fff;
  width: 1200px;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 5px;
  box-shadow: 0px 2px 15px 0px rgba(0, 26, 59, 0.26);
  color: #666666;
  padding-left: 30px;
}
.el-table--mini /deep/ td,
.el-table--mini /deep/th {
  padding: 0;
}
.el-table /deep/.cell {
  // padding: 0;
  // height: 20px;
  box-sizing: border-box;
  overflow: hidden;
}
.el-table /deep/ td {
  width: 100%;
}
.nav_title {
  font-weight: 700;
  font-size: 16px;
  line-height: 50px;
}
.nav_content {
  font-size: 16px;
  line-height: 50px;
}
#qrcode {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
